import cn from 'classnames';
import styles from './SliderBlock.module.scss';
import { ItemData } from '../Card/types';
import { ButtonType, Card, CardSize, CardType, TagPosition } from '../Card';

import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/effect-cards';

import { Autoplay, EffectCards, FreeMode } from 'swiper/modules';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import { ArrowButton } from '../ArrowButton';
import { Palettes, Variants } from '@/types';
import { Button } from '../Button';
import { useRouter } from 'next/router';
import { getCardRedirectUrl } from '@/utils/getCardRedirectUrl';
import { useEffect, useState } from 'react';
import { useAdaptiveWidth } from '@/hooks/useAdaptiveWidth';
import { useWindowWidth } from '@/hooks/useWindowWidth';

export enum MobileEffect {
  Cards = 'cards',
  Grid = 'grid',
  Slider = 'slider',
}

type SliderBlockProps = {
  cardType: CardType;
  items: ItemData[];
  button?: string;
  link: string;
  cardLink?: string;
  className?: string;
  mobileEffect?: MobileEffect;
  title?: string;
  size: CardSize;
  buttonType?: ButtonType;
  tagPosition?: TagPosition;
  noTypeCard?: boolean;
  withDescriptionCard?: boolean;
  noInfo?: boolean;
  withYear?: boolean;
};

export function SwiperButton({
  isNextButton,
  disabled,
}: {
  isNextButton?: boolean;
  disabled?: boolean;
}) {
  const swiper = useSwiper();

  const handleClick = () => {
    if (isNextButton) {
      swiper.slideNext();
    } else {
      swiper.slidePrev();
    }
  };

  return isNextButton ? (
    <ArrowButton
      variant={Variants.Outline}
      onClick={handleClick}
      disabled={disabled}
    />
  ) : (
    <ArrowButton
      variant={Variants.Outline}
      className={styles.prev}
      onClick={handleClick}
      disabled={disabled}
    />
  );
}

export function SliderBlock({
  cardType,
  items,
  button,
  link,
  mobileEffect = MobileEffect.Slider,
  title,
  className,
  cardLink,
  size,
  buttonType,
  tagPosition,
  noTypeCard,
  withDescriptionCard,
  noInfo,
  withYear,
}: SliderBlockProps) {
  const sliderClassName = cn(styles.slider, className);
  const sliderDefaultClassName = cn(
    styles.sliderDefault,
    {
      [styles.noButton]: !button,
    },
    className,
  );

  const { isMobile } = useAdaptiveWidth();
  const width = useWindowWidth();
  const router = useRouter();
  const [bannersFiltered, setBannersFiltered] = useState<ItemData[]>([]);

  useEffect(() => {
    setBannersFiltered([...items, ...items]);
  }, [items]);

  const handleMoreClick = () => {
    if (button) router.push(link);
    return;
  };

  const getShowButtonsValue = () => {
    if (width && width > 1100) {
      if (items.length < 5 && size === CardSize.M) {
        return false;
      }

      if (items.length < 5 && size === CardSize.SM) {
        return false;
      }

      if (items.length < 6 && size === CardSize.S) {
        return false;
      }

      if (items.length < 6 && size === CardSize.XS) {
        return false;
      }
    }

    if (width && width < 1100 && width > 767) {
      if (items.length < 5) {
        return false;
      }
    }

    if (width && width < 767) {
      if (items.length < 5) {
        return false;
      }
    }

    return true;
  };

  const getShowInfiniteValue = () => {
    if (width && width > 1100) {
      if (items.length < 5 && size === CardSize.M) {
        return false;
      }

      if (items.length < 6 && size === CardSize.SM) {
        return false;
      }

      if (items.length < 6 && size === CardSize.S) {
        return false;
      }

      if (items.length < 7 && size === CardSize.XS) {
        return false;
      }
    }

    if (width && width < 1100 && width > 767) {
      if (items.length < 5) {
        return false;
      }
    }

    if (width && width < 767) {
      if (items.length < 5) {
        return false;
      }
    }

    return true;
  };

  const showButtons = getShowButtonsValue();
  const isInfiniteSlider = getShowInfiniteValue();

  const getCardLink = (
    itemLink: string | undefined,
    type: string | undefined,
    code: string | undefined,
  ) => {
    if (itemLink) return itemLink;
    if (cardLink) return `${cardLink}/${code}`;
    if (type && code) return getCardRedirectUrl(type, code);
    if (code) return `${link}/${code}`;
    return link;
  };

  const buttonClassName = cn(styles.buttonMobile, 'custom-slider-button');

  const titleClassName = cn(styles.title, 'custom-slider-title');

  if (!items || items.length === 0) return null;

  if (mobileEffect === MobileEffect.Grid) {
    return (
      <>
        <div className={styles.grid}>
          <div className={styles.header}>
            {title && <h3 className={titleClassName}>{title}</h3>}
            {button && (
              <div className={styles.moreMobile}>
                <Button
                  text={button}
                  onClick={handleMoreClick}
                  palette={Palettes.Primary}
                  variant={Variants.Ghost}
                  className={buttonClassName}
                />
              </div>
            )}
          </div>
          <div className={styles.gridContent}>
            {items
              .map((item, index) => (
                <Card
                  key={`${item.name}-${index}`}
                  picture={item.picture}
                  link={getCardLink(item.link, item.type, item.code)}
                  name={item.name}
                  size={CardSize.XS}
                  button={item.button}
                  cardType={CardType.Panel}
                  text={item.tag}
                  tag={item.tag}
                  halfWidth
                  buttonType={buttonType}
                  fullWidth
                  closed={item.closed}
                  techClosed={item.techClosed}
                  buttonLink={item.buttonLink}
                  noInfo={noInfo}
                  pageMissing={item.pageMissing}
                  buttonDisabled={item.buttonDisabled}
                  withYear={withYear}
                />
              ))
              .slice(0, 6)}
          </div>
        </div>
        <Swiper
          slidesPerView="auto"
          spaceBetween={16}
          className={sliderClassName}
          freeMode
          modules={[FreeMode]}
          loop
        >
          <div className={styles.header}>
            {title && <h3 className={titleClassName}>{title}</h3>}
            {!isMobile && showButtons && (
              <div className={styles.buttons}>
                <SwiperButton />
                <SwiperButton isNextButton />
              </div>
            )}
            {button && isMobile && (
              <div className={styles.moreMobile}>
                <Button
                  text={button}
                  onClick={handleMoreClick}
                  palette={Palettes.Primary}
                  variant={Variants.Ghost}
                  className={buttonClassName}
                />
              </div>
            )}
          </div>
          {items.map((item, index) => (
            <SwiperSlide key={`${item.name}-${index}`} className="custom-slide">
              <Card
                picture={item.picture}
                tag={item.tag}
                link={getCardLink(item.link, item.type, item.code)}
                name={item.name}
                size={size}
                button={item.button}
                cardType={cardType}
                info={item.info}
                text={item.text}
                dateFrom={item.date || item.dateFrom}
                dateTo={item.dateTo}
                typeName={item.typeName}
                logo={item.logo}
                buttonType={buttonType}
                tagPosition={tagPosition}
                closed={item.closed}
                techClosed={item.techClosed}
                buttonLink={item.buttonLink}
                noInfo={noInfo}
                pageMissing={item.pageMissing}
                buttonDisabled={item.buttonDisabled}
                withYear={withYear}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {button && !isMobile && (
          <div className={styles.more}>
            <Button
              text={button}
              onClick={handleMoreClick}
              palette={Palettes.Secondary}
            />
          </div>
        )}
      </>
    );
  }

  if (mobileEffect === MobileEffect.Cards) {
    return (
      <>
        <div className={styles.headerCards}>
          {title && <h3 className={titleClassName}>{title}</h3>}
          {button && (
            <div className={styles.moreMobile}>
              <Button
                text={button}
                onClick={handleMoreClick}
                palette={Palettes.Primary}
                variant={Variants.Ghost}
              />
            </div>
          )}
        </div>
        <Swiper
          className={styles.cards}
          effect="cards"
          grabCursor
          modules={[EffectCards, Autoplay]}
          cardsEffect={{
            rotate: false,
            slideShadows: false,
            perSlideOffset: 9,
          }}
          loop
          centeredSlides
          loopAdditionalSlides={2}
          initialSlide={items.length}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          {bannersFiltered.map((item, index) => (
            <SwiperSlide key={`${item.name}-${index}`}>
              <Card
                picture={item.picture}
                tag={item.tag}
                link={getCardLink(item.link, item.type, item.code)}
                name={item.name || item.text}
                size={CardSize.XL}
                button={item.button}
                cardType={cardType}
                info={item.info}
                typeName={item.typeName}
                buttonType={buttonType}
                tagPosition={tagPosition}
                pictureMob={item.pictureMob}
                closed={item.closed}
                techClosed={item.techClosed}
                buttonLink={item.buttonLink}
                noInfo={noInfo}
                pageMissing={item.pageMissing}
                buttonDisabled={item.buttonDisabled}
                withYear={withYear}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Swiper
          slidesPerView="auto"
          spaceBetween={16}
          className={sliderClassName}
          freeMode
          modules={[FreeMode, Autoplay]}
          loop={isInfiniteSlider}
          autoplay={{
            delay: 5000,
            disableOnInteraction: true,
          }}
        >
          <div className={styles.header}>
            {title && <h3 className={titleClassName}>{title}</h3>}
            {!isMobile && showButtons && (
              <div className={styles.buttons}>
                <SwiperButton />
                <SwiperButton isNextButton />
              </div>
            )}
            {button && isMobile && (
              <div className={styles.moreMobile}>
                <Button
                  text={button}
                  onClick={handleMoreClick}
                  palette={Palettes.Primary}
                  variant={Variants.Ghost}
                  className={buttonClassName}
                />
              </div>
            )}
          </div>
          {items.map((item, index) => (
            <SwiperSlide key={`${item.name}-${index}`} className="custom-slide">
              <Card
                picture={item.picture}
                tag={item.tag}
                link={getCardLink(item.link, item.type, item.code)}
                name={item.name}
                size={size}
                button={item.button}
                cardType={cardType}
                info={item.info}
                text={item.text}
                dateFrom={item.date || item.dateFrom}
                dateTo={item.dateTo}
                typeName={item.typeName}
                logo={item.logo}
                buttonType={buttonType}
                tagPosition={tagPosition}
                closed={item.closed}
                techClosed={item.techClosed}
                buttonLink={item.buttonLink}
                noInfo={noInfo}
                pageMissing={item.pageMissing}
                buttonDisabled={item.buttonDisabled}
                withYear={withYear}
              />
            </SwiperSlide>
          ))}
        </Swiper>

        {button && !isMobile && (
          <div className={styles.more}>
            <Button
              text={button}
              onClick={handleMoreClick}
              palette={Palettes.Secondary}
            />
          </div>
        )}
      </>
    );
  }

  return (
    <>
      <Swiper
        slidesPerView="auto"
        spaceBetween={16}
        className={sliderDefaultClassName}
        freeMode
        modules={[FreeMode]}
        loop={isInfiniteSlider}
      >
        <div className={styles.header}>
          {title && <h3 className={titleClassName}>{title}</h3>}
          {!isMobile && showButtons && (
            <div className={styles.buttons}>
              <SwiperButton />
              <SwiperButton isNextButton />
            </div>
          )}
          {button && isMobile && (
            <div className={styles.moreMobile}>
              <Button
                text={button}
                onClick={handleMoreClick}
                palette={Palettes.Primary}
                variant={Variants.Ghost}
                className={buttonClassName}
              />
            </div>
          )}
        </div>
        {items.map((item, index) => (
          <SwiperSlide key={`${item.name}-${index}`} className="custom-slide">
            <Card
              picture={item.picture}
              tag={item.tag}
              link={getCardLink(item.link, item.type, item.code)}
              name={item.name}
              size={size}
              button={item.button}
              cardType={cardType}
              info={item.info}
              text={withDescriptionCard ? undefined : item.text}
              dateFrom={item.date || item.dateFrom}
              dateTo={item.dateTo}
              typeName={item.typeName}
              logo={item.logo}
              buttonType={buttonType}
              tagPosition={tagPosition}
              closed={item.closed}
              techClosed={item.techClosed}
              noType={noTypeCard}
              period={item.period}
              description={withDescriptionCard ? item.text : undefined}
              buttonLink={item.buttonLink}
              noInfo={noInfo}
              pageMissing={item.pageMissing}
              buttonDisabled={item.buttonDisabled}
              withYear={withYear}
            />
          </SwiperSlide>
        ))}
      </Swiper>

      {button && !isMobile && (
        <div className={styles.more}>
          <Button
            text={button}
            onClick={handleMoreClick}
            palette={Palettes.Secondary}
          />
        </div>
      )}
    </>
  );
}
